<template>
  <footer class=" myshadow">
	<div class="container">
		<div class="row align-items-center">

			<!-- Widget -->
			
			
			<!-- Widget -->
			<div class="col-md-8">
                <ul class="list-inline hstack flex-wrap mb-4 mt-2" style="justify-content: space-between;" >
									<li class="list-inline-item me-0 " ><a  style="margin-right:8px;color:#238cc8 !important" class="nav-link text-muted text-primary-hover" href="#terms&condition">Terms & Conditions </a></li>
									<li class="list-inline-item me-0"><a style="margin-right:8px;color:#238cc8 !important" class="nav-link text-muted text-primary-hover" href="#privacy_policy">Privacy Policy   </a></li>
									<li class="list-inline-item me-0 "><a  style="margin-right:8px;color:#238cc8 !important" class="nav-link text-muted text-primary-hover" href="#disclaimer_email_policy">Disclaimer & Email Policy  </a></li>
									<li class="list-inline-item me-0 "><a style="margin-right:8px;color:#238cc8 !important" class="nav-link text-muted text-primary-hover" href="#contact_us">Contact Us </a></li>
									<li class="list-inline-item me-0 "><a style="color:#238cc8 !important" class="nav-link text-muted text-primary-hover" href="#faq">FAQ's Guide</a></li>
								</ul>
				 <div class="mb-" style="color:black"  >Copyrights <a href="#" style="color:black" class="">©2018 - {{ new Date().getFullYear() }}</a>. All rights reserved | BidsPortal</div>
								
				<!-- <div class="text-white text-primary-hover"> Copyrights <a href="#" class="text-white">©2022 Booking</a>. All rights reserved. </div> -->
			</div>

			<!-- Widget -->
			<div class="col-md-4">
				<ul class="list-inline mb-0 text-center text-md-end mb-4 mt-2">
					  <!-- <a  href="tel:+91 512-487-7757" class="btn btn-sm btn-primary-soft"><i class="bi bi-phone me-2"></i>+1 512-487-7757</a> -->
					<li class="list-inline-item ms-2"><a class="nav-link"  href="javascript:void(0)" style="color:#238cc8 !important"><i class="fa-solid fa fa-globe "></i> (913) 232-2255</a></li>
					
				</ul>
					<ul class="list-inline mb-0 text-center text-md-end mb-2">
						<!-- class=" fab fa-facebook-f facebookBtn smGlobalBtn" -->
					<li class="list-inline-item ms-2"> <a class="mb-0" style="font-size:22px !important " href="https://facebook.com/bidsportal" target="_blank"><i class="fab fa-fw fa-facebook-f"></i></a></li>
					<li class="list-inline-item ms-2"> <a class="mb-0" style="font-size:22px !important; color:red" href="https://twitter.com/bidsportal" target="_blank"><i class="twitterBtn fab fa-twitter "></i></a></li>
					<li class="list-inline-item ms-2"> <a class="mb-0" style="font-size:22px !important" href="https://www.youtube.com/@bidsportal" target="_blank"><i class="youtubeBtn fab fa-youtube"></i></a></li>
					<li class="list-inline-item ms-2"> <a class="mb-0" style="font-size:22px !important" href="https://www.linkedin.com/in/bidsportal" target="_blank"><i class="linkedinBtn  fab fa-linkedin-in smGlobalBtn"></i></a></li>
				</ul>
			</div>
		</div>
       
	</div>
</footer>
</template>
<style scoped>
.myshadow {

  
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
 box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.twitterBtn {
    /* background: #00ABE3; */
    color: #00abe3;
}
.youtubeBtn {
    /* background: #00ABE3; */
    color: red;
}
.linkedinBtn {
    color: #0094bc;
}
</style>