<template>
    <section class="pt-4 pt-lg-5" id="terms&condition">
        <div class="container">
            <!-- Title -->
            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="fs-2 mb-0">Terms & Conditions</h1>
                </div>
            </div>

            <div class="ps-2 ps-md-3">
                <div class="text-center">
                    <img src="../assets/icons/Terms.png" class="imgcol" width="150" />

                    <p>By accessing BidsPortal website, you are agreeing to be bound by the Terms of Use, Privacy Policy and Disclaimer and Email Policy.</p>
                </div>
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <p>
                            BidsPortal may provide communication tools such as email, bulletin boards, chat areas, news groups, classifieds, forums and/or other message or communication facilities ("the Services") designed to enable you to
                            communicate with others. Unless stated otherwise the Services are for your personal and organization’s use only. Your access to and use of bidsportal.com ("the Website") and the Services outlined, are subject
                            exclusively to these Terms and Conditions.
                        </p>
                        <p>
                            You will not use BidsPortal for any purpose that is unlawful or prohibited by these Terms and Conditions. By using BidsPortal you are fully accepting the terms, conditions and disclaimers contained in this
                            notice. If you do not accept these Terms and Conditions you must immediately stop using BidsPortal.
                        </p>
                        <p class="mb-0">
                            BidsPortal reserves the right to update or amend these Terms and Conditions at any time and your continued use of BidsPortal following any changes shall be deemed to be your acceptance of such change. It is
                            therefore your responsibility to check the Terms and Conditions regularly for any changes.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">1. INTELLECTUAL PROPERTY RIGHTS</h6>
                        <p>
                            BidsPortal website and its content (including without limitation the Website design, text, graphics and all software and source codes connected with the Website and the Services) are protected by copyright,
                            trademarks, patents and other intellectual property rights and laws.
                        </p>

                        <p class="mb-0">
                            In accessing the BidsPortal you agree that you will access the contents solely for your organization’s use. None of the content may be sold or distributed without the prior written consent of the copyright
                            holder. This excludes any downloading, copying, printing and transmitting of pages of BidsPortal or the contents for use by your organization in its pursuit of business with other users of BidsPortal.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">2. CONFIDENTIALITY</h6>
                        <p>
                            BidsPortal respects the confidentiality of private and personal information of all cooperating parties. For more information about the procedure of collection, use and disclosure of the User’s confidential
                            information, refer to the Privacy Policy. The User is advised to become familiar with this document.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">3. SITE UPDATES</h6>
                        <p>
                            BidsPortal regularly updates technical, content and other information on the Site. Nevertheless, BidsPortal makes no claim guaranteeing the completeness, accuracy and compliance of the information given at the
                            Site. BidsPortal is able to add, review or delete information, resources and services posted on the Site. Also, BidsPortal reserves the right to make changes to the Site without prior notification.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">4. LINKS TO THIRD-PARTY WEBSITES</h6>
                        <p>
                            BidsPortal website may include links to third party websites that are controlled and maintained by others. Any link to other websites is not an endorsement of such websites and you acknowledge and agree that
                            BidsPortal is not responsible for the content or availability of any such sites.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">5. ACCEPTABLE USES</h6>
                        <p>
                            You acknowledge that all information, text, graphics, logos, photographs, images, moving images, sound, illustrations and other materials ("the Content"), whether posted publicly or transmitted privately, are the
                            sole responsibility of the person from whom such Content originated. BidsPortal does not control or endorse the Content and cannot guarantee the accuracy, integrity or quality of such Content and you acknowledge
                            that by using the Services you may be exposed to content that is offensive and/or indecent. BidsPortal will not be liable in any way for any Content or for any loss or damage of any kind resulting from the use of
                            any Content transmitted via the Services and you agree to bear all risks associated with the use of any Content, including any reliance on the accuracy or completeness of such Content.
                        </p>

                        <ul class="list-group list-group-borderless mb-0">
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>In using BidsPortal website you agree not to.</li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Use the Services to send junk email, spam, chain letters, pyramid schemes or any other unsolicited messages, commercial or otherwise.
                            </li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Post, publish, distribute or disseminate material or information that is defamatory, infringing, obscene, indecent, threatening, abusive, harassing or
                                unlawful.
                            </li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Post, publish, distribute or disseminate material or information that incites discrimination, hate or violence towards any person or group on account of
                                their race, religion, disability, nationality or otherwise.
                            </li>

                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Threaten abuse, disrupt, stalk or otherwise violate the legal rights (including rights of privacy and publicity) of others.
                            </li>

                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Violate any applicable laws or regulations.</li>

                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Use BidsPortal in any manner that could damage, disable, overburden or impair BidsPortal or interfere with any other party's use and enjoyment of BidsPortal
                                services.
                            </li>

                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Post, publish, distribute or disseminate material or information that you do not have a right to transmit under any law or under contractual or fiduciary
                                relationships (such as inside information or confidential information disclosed in the course of employment or under a confidentialityagreement)
                            </li>

                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Attempt to gain unauthorised access to any of the Services, other accounts, computer systems or networks connected to BidsPortalthrough hacking, password
                                mining or similar.
                            </li>

                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Use any information or material in any manner that infringes any copyright, trademark, patent or other proprietary right of any party.
                            </li>

                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Make available or upload files that contain a virus, worm, trojan or corrupt data that may damage the operation of the computer or property of another.
                            </li>

                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Collect or store personal information about others, including email addresses.</li>

                            <li class="list-group-item d-flex text-body mb-2"><i class="fa-solid fa-check text-warning me-2"></i>Impersonate any person or entity for the purpose of misleading others.</li>
                        </ul>
                        <p class="mb-0">
                            BidsPortal has no obligation to monitor the Services but shall be entitled to review materials posted to a communications facility and, at its sole discretion, to remove any material that breaches these Terms and
                            Conditions or is otherwise objectionable.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">6. USER ACCOUNT, PASSWORD AND SECURITY</h6>
                        <p>
                            You will be required to complete the registration process by providing certain information and registering a username and password for use with BidsPortal. You are responsible for maintaining the confidentiality
                            of the username and password and also for all activities that take place under your account.
                        </p>
                        <p class="mb-0">
                            You agree to immediately notify BidsPortal of any unauthorized use of your password or account or any other breach of security. In no event will the company be liable for any indirect or consequential loss or
                            damage whatsoever resulting from the disclosure of your username and/or password. You may not use another person's account at any time, without the express permission of the account holder.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">7. TERMINATION</h6>
                        <p>
                            BidsPortal has the right to terminate your access to any or all of the Services at any time, without notice, for breach of these Terms and Conditions.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">8. INDEMNITY</h6>
                        <p>
                            You agree to indemnify and hold BidsPortal and its employees and agents harmless from and against any breach by you of these Terms and Conditions and any claim or demand brought against the company by any third
                            party arising out of your use of the Services and/or any Content submitted, posted or transmitted through the Services, including without limitation, all claims, actions, proceedings, losses, liabilities,
                            damages, costs, expenses (including reasonable legal costs and expenses) howsoever suffered or incurred by BidsPortal in consequence of your breach of these Terms and Conditions.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">9. DISCLAIMERS AND LIMITATION OF LIABILITY</h6>
                        <p>
                            Use of BidsPortal is at your own risk. The services are provided on an "AS IS" and "AS AVAILABLE" basis without any representation or endorsement made and without warranty of any kind whether express or implied,
                            including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.
                        </p>
                        <p>
                            To the extent permitted by law, BidsPortal will not be liable for any indirect or consequential loss or damage whatsoever (including without limitation loss of business, opportunity, data, profits) arising out of
                            or in connection with the use of BidsPortal.
                        </p>
                        <p>
                            BidsPortal makes no warranty that the Content will be accurate or reliable, that the functionality of the website or services will be uninterrupted or error free, or that the website or services or the server
                            that makes them available are free of viruses or anything else that may be harmful or destructive.
                        </p>
                        <p class="mb-0">
                            Nothing in these Terms and Conditions shall be construed so as to exclude or limit the liability of BidsPortal for death or personal injury as a result of the negligence of BidsPortal or that of its employees or
                            agents. Nothing in these Terms and Conditions shall affect your statutory rights as a consumer.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">10. SEVERANCE</h6>
                        <p>
                            If any of these Terms and Conditions should be determined to be invalid, illegal or unenforceable for any reason by any court of competent jurisdiction then such Term or Condition shall be severed and the
                            remaining Terms and Conditions shall survive and remain in full force and effect and continue to be binding and enforceable.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">11. GOVERNING LAW</h6>
                        <p>
                            The validity, interpretation and enforcement of this Terms of Use, matters arising out of or related to performance or breach of this Terms of Use, and related matters shall be governed by the internal laws of
                            the United States of America. Any legal action or proceeding concerning the validity, interpretation and enforcement of this Terms of Use, matters arising out of or related to performance or breach of this Terms
                            of Use, and related matters, shall be brought exclusively in the United States of America. All parties consent to the exclusive jurisdiction of those courts, waiving any objection to the propriety or convenience
                            of such venues.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">12. STATUS AND AMENDMENT</h6>
                        <p>
                            BidsPortal may at its sole discretion, amend this policy from time to time at which the amended policy will immediately come into effect in respect of continued usage of this website.
                        </p>
                        <p class="mb-0">
                            Privacy Policy page is part of Terms and Conditions of Use of this website. If you do not agree with this Policy then you may not use this website.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">13. CONTACT INFORMATION</h6>
                        <p>
                            For any questions in connection with the provisions of this document, please contact BidsPortal using the contact information below:<br />
                            Email: support@bidsportal.com
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
