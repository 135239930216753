<template>
    <section class="pt-4 pt-lg-5" id="privacy_policy">
        <div class="container">
            <!-- Title -->
            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="fs-2 mb-0">Privacy Policy</h1>
                </div>
            </div>

            <div class="ps-2 ps-md-3">
                <div class="text-center">
                    <img src="../assets/icons/Privacy.png" class="imgcol" width="150" />

                    <p>By accessing BidsPortal website, you are agreeing to be bound by the Terms of Use, Privacy Policy and Disclaimer and Email Policy.</p>
                </div>
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <p>
                            This Privacy Policy sets out how BidsPortal uses and protects any information that you ("you", "your") make available to us in connection with or when you use this website as well as the services BidsPortal
                            provides. If you choose to use our service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the
                            service. We will not use or share your information with anyone except as described in this Privacy Policy.
                        </p>
                        <p>
                            BidsPortal is committed to ensuring that your privacy is protected and will only use your information in accordance with the Data Protection Act. Should we ask you to provide certain information by which you can
                            be identified when using this website, then you can be assured that it will only be used in accordance with this Privacy Policy.
                        </p>
                        <p>
                            This Privacy Notice is designed to cover the BidsPortal website (bidsportal.com) and BidsPortal services. If there are any variations or specific elements unique to a BidsPortal service, that will be highlighted
                            in its own section, later in this policy.
                        </p>
                        <p class="mb-0">
                            The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, unless otherwise defined in this Privacy Policy.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">1. CONSENT TO INFORMATION COLLECTION</h6>
                        <p>
                            During work with the Site, technical non-personal information about the User such as the User's IP address, address of another site the User came to the Site from, time of the visit, and browser type and language
                            version, may be collected automatically. We may also collect and analyze navigational information, namely the address and path of the User through the Site, actions performed at the Site, jumps to and from inner
                            links, etc.
                        </p>
                        <p>
                            Personal information of the User is collected only upon prior permission from the User to collect and use certain information within these rules. Giving such permission, the User supplies such information
                            voluntarily.
                        </p>
                        <strong>"Personal information" includes:</strong>
                        <ul class="list-group list-group-borderless mb-0">
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Information supplied by the User during registration at the Site, including the login and password, personal data (full name), and e-mail. When you register
                                as a Subscriber on the Site - we collect additional information such as postal address and contact phone number;
                            </li>
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Information supplied by the User during purchase, such as a billing address and the credit card number;</li>
                        </ul>

                        <p class="mb-0">
                            BidsPortal never collects or requires Users to provide information revealing racial or ethnic origin of Users, their political opinions, religious or philosophical beliefs, trade-union membership, and data
                            concerning their health or sex life. The user has the right to refuse the submission of personal information at any time. However, in this case, some functions and services offered at the Site may be degraded
                            and/or made unavailable, in part or in its entirety.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">2. USE OF PERSONAL INFORMATION</h6>
                        <strong>Collection and use of the User's personal information is performed by BidsPortal for the following purposes:</strong>
                        <ul class="list-group list-group-borderless mb-0">
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Creation, management and maintenance of an account;</li>
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Processing of the User's inquiries and operations at the Site;</li>
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Customer service;</li>
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Data analysis for the purposes of improving offered producs, services or technologies;</li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Provision and display of the content specially chosen in accordance with preferences and interests of the User;
                            </li>
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Maintenance of operation of interactive areas;</li>
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Identification of the supplied files as belonging to a certain Subscriber;</li>
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Realization of communication and contacts on matters concerning transactions made by the User;</li>
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Notification about new products and services to the Site User.</li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>BidsPortal collects personal information about its Users only, and does not require User’s to provide BidsPortal with personal information of any third
                                persons, except for case of sharing the Bids as described herein.
                            </li>
                        </ul>
                        <p>
                            BidsPortal has the ability to send communications concerning transactions performed by the User, payment reminders and questions concerning purchase confirmation.
                        </p>
                        <p>
                            Users are free to select one-click opt-out possibility to unsubscribe from promotional and advertising communications. BidsPortal will not further send you promotional / advertising messages once your objection
                            will be received. This one-click subscription is not available for transactional messages that are generally required. To opt-out of communications connected to the transactions performed, Users shall deactivate
                            their accounts. For avoidance of doubts, our promotional and advertising messages will always be clearly identified as promotional and/or advertising, and will always contain both return e-mail address of
                            BidsPortal.
                        </p>
                        <p class="mb-0">
                            Personal information gathered by BidsPortal may be processed and stored in other countries at facilities belonging to BidsPortal and/or legally used by BidsPoratl. Supplying BidsPortal with the private
                            information, User permits its transfer outside the country within the bounds of such use.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">3. PERSONAL INFORMATION DISCLOSURE</h6>
                        <p>
                            Except in situations clearly stated within these rules, BidsPortal does not disclose, sell, lease, lend, rent or make available personal information of the User to any third parties.
                        </p>
                        <p>
                            The User's personal information can be disclosed and shared with partner agencies that render services on behalf of the BidsPortal. The services may include money transfer, data storage, payment processing, etc.
                            These third parties will receive only the information required to provide said specific services; the information will be kept and protected by the same principles and level of protection followed by BidsPortal,
                            and it can not be used for any other purposes.
                        </p>

                        <p>
                            Information about Users may also be passed and disclosed as a business asset in the case of acquisition, sale or absorption of the company. In the event of this unlikely case, BidsPortal will inform Users in
                            accordance with the procedure outlined below.
                        </p>
                        <p class="mb-0">
                            BidsPortal reserves the right to disclose and pass personal information of the User if it is required by law and when we believe that disclosure is necessary to protect our rights and/or to comply with a judicial
                            proceeding, court order, or legal process served on our Site.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">4. ACCESS TO OWN PERSONAL INFORMATION</h6>
                        <p>
                            Information indicated by the User at the Site can be reviewed and edited in the section «Profile Menu» or you may contact us at <span style="color: blue;">support@bidsportal.com</span>. We will respond to your
                            request for access within 30 days.
                        </p>
                        <p class="mb-0">
                            We will retain your information for as long as your account is active or as needed to provide you services. If you wish to cancel your account or request that we delete or no longer use your information to
                            provide you services, contact us at <span style="color: blue;">support@bidsportal.com</span>. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and
                            enforce our agreements.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">5. USE OF COOKIES AND OTHER TECHNOLOGIES</h6>
                        <p>
                            BidsPortal and our partners use cookies or similar technologies to analyze trends, administer the website, track users’ movements around the website, and gather demographic information about our user base as a
                            whole. Users can control the use of cookies at the individual browser level.
                        </p>
                        <p>
                            We partner with a third-party ad network to either display advertising on our Site or to manage our advertising on other sites. Our ad network partner uses cookies and Web beacons to collect non-personally
                            identifiable information about your activities on this and other Web sites to provide you targeted advertising based upon your interests. If you wish to not have this information used for the purpose of serving
                            you targeted ads, you may opt-out.
                        </p>

                        <p>
                            The User can allow or forbid the use and acceptance of cookies on his/her computer. If cookies are blocked by the User, it may prevent the User from being able to register or perform other interactive functions
                            of the Site, but it will not affect the User’s ability to perform general navigation within the Site.
                        </p>

                        <p class="mb-0">
                            Email information collected for sending bid alerts is not stored or used for any other purpose. That information is not shared with any other parties.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">6. PROTECTION OF THE USER'S PERSONAL INFORMATION</h6>
                        <strong>BidsPortal actively monitors and enforces the information protection standards during all BidsPortal' activity, namely:</strong>
                        <ul class="list-group list-group-borderless mb-0">
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Limitation of the rendered access to information only to those employees who need it for providing services to the User;
                            </li>
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>Signature of confidentiality treaties by employees;</li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Signature by partners and external companies invited for fulfillment of particular functions of confidentiality treaties and agreements concerning non-use of
                                such information for any unauthorized purposes;
                            </li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Encryption of payment data such as credit card using SSL technology through the use of our third-party payment gateway service providers;
                            </li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Storing of the personal information obtained from the User in encrypted form, at protected computers secured from unauthorized access.
                            </li>
                        </ul>

                        <p class="mb-0">
                            Since there is no perfect secure method of protecting information during its storage, processing and transfer into electronic form, BidsPortal does not give the full warranty of its complete safety, despite all
                            efforts made by the company. If information of an account and personal data of the User are protected with a password, safety and nondisclosure of this password are responsibility of the User him/herself.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">7. MINOR WEBSITE USERS</h6>
                        <p>
                            Site, Site content and services are neither intended to be used by minors and persons under age of 18, nor designed and intended to willfully and knowingly collect, use, or disclose any personal information from
                            minors and persons under age of 18. BidsPortal will immediately remove personal information from children and/or search to obtain a verifiable parental consent for the collection use, or disclosure of personal
                            information from children, once it will come to BidsPortal’ attention that any personal information from children was submitted or collected through the Site.
                        </p>
                        <p class="mb-0">
                            The User confirms and warrants that he/she has reached the age of 18, has all the sufficient for usage of the Site and is bound by legal obligations sufficient for any liability that can appear as a result of
                            usage of the Site services. The User agrees to bear full responsibility for all cases of the Site usage without distinction, as well as for use of his/her login and password by third parties including minors and
                            persons under age of 18 living with him/her.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">8. THIRD-PARTY LINKS</h6>
                        <p>
                            The Site pages can contain links to sites, services and functions of third parties, subsidiaries and affiliates that may use and conform to different rules and procedures of confidentiality provision from those
                            stated here. BidsPortal is not responsible for policy of confidentiality of such resources.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">9. SOCIAL MEDIA WIDGETS</h6>
                        <p>
                            Our Site includes Social Media Features, such as the Facebook, LinkedIn, and Widgets, such as the Share This button, or interactive mini-programs that run on our Site. These Features may collect your Internet
                            protocol address, which page(s) you are visiting on our Site, and may set a cookie to enable the Feature to function properly. Social Media Features and Widgets are either hosted by a third party or hosted
                            directly on our Site. Your interactions with these Features are governed by the privacy statement of the company providing it.
                        </p>
                    </div>
                </div>
            </div>
            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">10. STATUS AND AMENDMENT</h6>
                        <p>
                            BidsPortal may at its sole discretion, amend this policy from time to time at which the amended policy will immediately come into effect in respect of continued usage of this website.
                        </p>
                        <p class="mb-0">
                            <span style="color: blue;"> Terms & Conditions</span> and<span style="color: blue;"> Disclaimer & Email Policy</span> page is part of Privacy Policy of this website. If you do not agree with this terms, then you
                            may not use this website.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
